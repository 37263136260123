/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import Mongo from "../../assets/images/backend/mongodb.svg";
import Postgres from "../../assets/images/backend/postgresql.svg";
import Mysql from "../../assets/images/backend/mysql.svg";
import Redshift from "../../assets/images/backend/aws-redshift.svg";
import AwsAthena from "../../assets/images/backend/aws-athena.svg";
export default function DataTech() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">

          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <a href="https://www.mongodb.com/" target="_blank">
              <img className="h-12" src={Mongo} alt="MongoDB" />
            </a>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <a href="https://www.postgresql.org/" target="_blank">
              <img className="h-12" src={Postgres} alt="Postgres" />
            </a>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <a href="https://www.mysql.com/" target="_blank">
              <img className="h-12" src={Mysql} alt="MySQL" />
            </a>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <a href="https://aws.amazon.com/redshift/" target="_blank">
              <img className="h-12" src={Redshift} alt="AWS Redshift" />
            </a>
          </div>
          <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
            <a href="https://aws.amazon.com/athena/" target="_blank">
              <img className="h-12" src={AwsAthena} alt="AWS Athena" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
